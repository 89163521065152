import { observable, makeAutoObservable, action } from "mobx";
import { User as UserModel } from "../models/User";

class AuthenticationsStore {
  private initUserStore = {
    id: "",
    username: "",
    email: "",
    active: true,
    role: {},
    lastLogin: "",
    createdAt: "",
    updatedAt: "",
    tenant: {},
  };
  @observable isLoggedIn: boolean = false;
  @observable user: UserModel = {
    ...this.initUserStore,
  };
  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetIsLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }
  @action
  onSetUser(user: UserModel) {
    this.user = { ...user };
  }
  @action
  onClearStore() {
    this.isLoggedIn = false;
    this.user = this.initUserStore;
  }
}
export default AuthenticationsStore;
