export const InitialUser = {
  id: "",
  username: "",
  email: "",
  active: true,
  role: {},
  lastLogin: "",
  createdAt: "",
  updatedAt: "",
  tenant: {},
};
