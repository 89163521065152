import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Grid, Stack, Typography, Box, Button } from "@mui/material";
import AuthenticatedLayout from "../../layouts/Authenticated";
// import Switcher from "../../components/inputs/Switcher";
import FormPatient from "../../components/patients/Form";
import { useStores } from "../../stores";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../constants/Route";

interface Props {}

const Preview = observer((props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { PatientStore } = useStores();

  useEffect(() => {
    PatientStore.onSetIsReadOnly(true);
    PatientStore.GetPatientById(params.patientId || "");
  }, [PatientStore, params]);

  const onNavigateToPatientList = () => {
    navigate(PathsConstant.patientList);
  };
  const onNavigateToPatientEdit = () => {
    const { patientId } = params;
    navigate(`${PathsConstant.patientEdit}/${patientId}`);
  };

  return (
    <AuthenticatedLayout>
      <>
        <Stack
          flexDirection="row"
          alignItems="center"
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
        >
          <Typography variant="h5">Patient</Typography>
          {/* <Box marginLeft="auto">
            <Switcher
              isChecked={PatientStore.patient?.isActive || false}
              isDisabled={PatientStore.isReadOnly}
              onChange={() => {}}
            />
          </Box> */}
        </Stack>
        <Box marginY={4} id="patientForm">
          <FormPatient />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              className="canceled"
              onClick={onNavigateToPatientList}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onNavigateToPatientEdit}>
              Edit Form
            </Button>
          </Grid>
        </Grid>
      </>
    </AuthenticatedLayout>
  );
});

export default Preview;
