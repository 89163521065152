import Axios from "../../plugins/Axios";

const GetSearchHistories = async (query: Object) => {
  try {
    const { data } = await Axios.get("/histories/search", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetCountHistoriesBySearch = async (query: Object) => {
  try {
    const { data } = await Axios.get("/histories/count", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const OnCreateHistory = async (body: any, patientId: string) => {
  try {
    const { data } = await Axios.post(`/histories/patients/${patientId}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DeleteHistory = async (id: string) => {
  try {
    const { data } = await Axios.delete(`/histories/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetHistoryById = async (id: string) => {
  try {
    const { data } = await Axios.get(`/histories/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const UpdateHistoryById = async (body: any, id: string) => {
  try {
    const { data } = await Axios.patch(`/histories/${id}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportHistoryByIdAsPdf = async (id: string) => {
  try {
    const { data } = await Axios.get(`/histories/${id}/pdf`, {
      responseType: "blob",
    });
    return {
      success: true,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportHistoryByIdAsImage = async (id: string) => {
  try {
    const { data } = await Axios.get(`/histories/${id}/image`, {
      responseType: "blob",
    
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  GetSearchHistories,
  OnCreateHistory,
  GetCountHistoriesBySearch,
  DeleteHistory,
  GetHistoryById,
  UpdateHistoryById,
  ExportHistoryByIdAsPdf,
  ExportHistoryByIdAsImage,
};
