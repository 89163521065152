import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  Button,
  Box,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import {
  Person as PersonIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import AuthenticatedLayout from "../../layouts/Authenticated";
import SearchAndFilterByDateAndKeyword from "../../components/filters/SearchAndFilterByDateAndKeyword";
import SimpleTable from "../../components/tables/Simple";
import ModalDelete from "../../components/modals/Delete";
import ModalFail from "../../components/modals/Fail";
import LoadingWholeScreen from "../../components/loading/WholeScreen";
import Sorting from "../../components/filters/Sorting";
import { Paths as PathsConstant } from "../../constants/Route";
import {
  GetPatientsBySearch as GetPatientsBySearchService,
  GetCountPatientsBySearch as GetCountPatientsBySearchService,
  OnDeletePatient as OnDeletePatientService,
} from "../../services/Patient";
import { DeleteColorectalByPatientId as DeleteColorectalByPatientIdService } from "../../services/colorectals/ColorectalSurgery";
import { IdCardType as IdCardTypeConstant } from "../../constants/Personal";
import { RowsPerPage as RowsPerPageConstant } from "../../constants/Table";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../../utils/InputValidation";
import { Patient as PatientModel } from "../../models/Patient";
import { useStores } from "../../stores";

interface Props {}

const List = (props: Props) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: "HN",
      align: "center",
      className: "min-w-100",
    },
    {
      name: "Country",
      align: "center",
      className: "min-w-120",
    },
    {
      name: "ID Card",
      align: "center",
      className: "min-w-160",
    },
    {
      name: "Passport / Foreigner ID",
      align: "center",
      className: "min-w-160",
    },
    {
      name: "Full Name",
      align: "center",
      className: "min-w-250",
    },
    {
      name: "Action",
      align: "center",
      className: "",
    },
  ];
  const { PatientStore } = useStores();
  const [patients, setPatients] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [isLoadingWholeScreen, setIsLoadingWholeScreen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [patientSelected, setPatientSelected] = useState<any>(null);
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    keyword: "",
    startDate: "",
    endDate: "",
  });
  const [sorting, setSorting] = useState<any>({ created_at: -1, reference_no: -1 });
  const [currentPage, setCurrentPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);

  let onGetSearchPatients = () => {};
  let onGetCountPatients = () => {};

  useEffect(() => {
    PatientStore.onClearStore();
  }, [PatientStore]);
  useEffect(() => {
    onGetSearchPatients();
  }, [currentPage, rowPerPage, searchQuery, sorting]);
  useEffect(() => {
    onGetCountPatients();
  }, [searchQuery]);

  onGetSearchPatients = async () => {
    setIsLoading(true);
    setPatients([]);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
      ...(sorting && { sort: JSON.stringify({ ...sorting }) }),
      skip: currentPage * rowPerPage,
      limit: rowPerPage,
    };
    const response = await GetPatientsBySearchService({ ...params });
    response.success && setPatients(response.data);
    setIsLoading(false);
  };
  onGetCountPatients = async () => {
    setIsLoadingCount(true);
    setTotalPatients(0);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
    };
    const response = await GetCountPatientsBySearchService({ ...params });
    response.success && setTotalPatients(response.data);
    setIsLoadingCount(false);
  };
  const handleOpenModalDelete = (selected: PatientModel) => {
    setPatientSelected(selected);
    setIsModalDeleteOpen(true);
  };
  const handleCloseModalDelete = async () => {
    setIsModalDeleteOpen(false);
    setPatientSelected(null);
  };
  const handleDeletePatient = async () => {
    setIsModalDeleteOpen(false);
    setIsLoadingWholeScreen(true);
    const responseDeleteColorectal = await DeleteColorectalByPatientIdService(
      patientSelected.id
    );
    if (!responseDeleteColorectal.success) {
      setIsLoadingWholeScreen(false);
      setIsShowFailModal(true);
      return;
    }
    const response = await OnDeletePatientService(patientSelected.id);
    setIsLoadingWholeScreen(false);
    if (response.success) {
      handleCloseModalDelete();
      onGetSearchPatients();
      onGetCountPatients();
    } else {
      setIsShowFailModal(true);
    }
  };
  const onNavigateToDashboard = (selected: PatientModel) => {
    navigate(`${PathsConstant.colorectalSurgeryDashboard}/${selected.id}`);
  };
  const onNavigateToPatientEdit = (selected: PatientModel) => {
    navigate(`${PathsConstant.patientEdit}/${selected.id}`);
  };
  const onResetSearchPatient = () => {
    setSearchQuery({
      keyword: "",
      startDate: "",
      endDate: "",
    });
  };
  const onSubmitSearch = (query: any) => {
    setSearchQuery({ ...query });
    handleChangePage(0, rowPerPage);
  };
  const handleChangePage = (page: number, rowPerPage: number) => {
    setCurrentPage(page);
    setRowPerPage(rowPerPage);
  };
  const handleSorting = (value: any) => {
    setSorting(value);
  };

  return (
    <div>
      <AuthenticatedLayout>
        <>
          {isLoadingWholeScreen && (
            <LoadingWholeScreen isShowDescription={true} />
          )}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <Typography variant="h5">List of Patient</Typography>
            <Box
              marginLeft={{ sm: "auto" }}
              marginTop={{ xs: 2, sm: 0 }}
              width={{ xs: "100%", sm: "auto" }}
            >
              <Button
                variant="contained"
                onClick={() => navigate(PathsConstant.patientCreate)}
              >
                <PersonIcon />
                Create Patient
              </Button>
            </Box>
          </Stack>
          <Box marginY={4}>
            <SearchAndFilterByDateAndKeyword
              placeholderKeyword="Search by first name, last name, hn, id card"
              onReset={onResetSearchPatient}
              onSearch={(body: any) => onSubmitSearch(body)}
            />
          </Box>
          <Stack marginBottom={2} alignItems="flex-end">
            <Sorting
              defaultSortingValue="Created (Newest)"
              onSorting={(value: any) => handleSorting(value)}
            />
          </Stack>
          <SimpleTable
            columns={columns}
            totalRows={totalPatients}
            page={currentPage}
            isLoading={isLoading || isLoadingCount}
            onChangePage={(page: number, rowPerPage: number) =>
              handleChangePage(page, rowPerPage)
            }
          >
            <>
              {patients?.map((patient: any, patientIndex: number) => {
                return (
                  <TableRow hover tabIndex={-1} key={patientIndex}>
                    <TableCell align="center" className={columns[0]?.className}>
                      <Typography variant="subtitle1">
                        {(patient.hn && FormatDisplayHnUtil(patient.hn)) || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={columns[1]?.className}>
                      <Typography variant="subtitle1">
                        {patient.country?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={columns[2]?.className}>
                      <Typography variant="subtitle1">
                        {(patient.idCardType === IdCardTypeConstant.idCard &&
                          patient.idCard) ||
                          "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={columns[3]?.className}>
                      <Typography variant="subtitle1">
                        {(patient.idCardType !== IdCardTypeConstant.idCard &&
                          patient.idCard) ||
                          "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={columns[4]?.className}>
                      <Typography variant="subtitle1">
                        {patient.firstName} {patient.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`cell-action ${columns[4]?.className}`}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Box
                            className="action-button selected-icon"
                            onClick={() => onNavigateToDashboard(patient)}
                          >
                            <DescriptionIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            className="action-button edit-icon"
                            onClick={() => onNavigateToPatientEdit(patient)}
                          >
                            <EditIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            className="action-button delete-icon"
                            onClick={() => handleOpenModalDelete(patient)}
                          >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          </SimpleTable>
          <ModalDelete
            isOpen={isModalDeleteOpen}
            title="Delete patient"
            description="Are you sure you want to delete patient"
            information={`${patientSelected?.firstName || ""} ${
              patientSelected?.lastName || ""
            }`}
            onClose={handleCloseModalDelete}
            onDelete={handleDeletePatient}
          />
          <ModalFail
            isOpen={isShowFailModal}
            title="Delete patient"
            description="Failed to delete a patient. Please try again."
            onClose={() => {
              setIsShowFailModal(false);
            }}
          />
        </>
      </AuthenticatedLayout>
    </div>
  );
};

export default List;
